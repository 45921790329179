// Posts and pages
//
// Each post is wrapped in `.post` and is used on default and post layouts. Each
// page is wrapped in `.page` and is only used on the page layout.

.page,
.post {
  margin-bottom: 4em;

  li + li {
    margin-top: .25rem;
  }
}

// Blog post or page title
.page-title,
.post-title,
.post-title a {
  color: #303030;
}
.page-title,
.post-title {
  margin-top: 0;
}

// Meta data line below post title
.post-date {
  display: block;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  color: #9a9a9a;
}


// Related posts
.related {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.related-posts {
  padding-left: 0;
  list-style: none;

  h3 {
    margin-top: 0;
  }

  li {
    small {
      font-size: 75%;
      color: #999;
    }

    a:hover {
      color: #268bd2;
      text-decoration: none;

      small {
        color: inherit;
      }
    }
  }
}

// Related posts

.archive {
  margin-top: 1rem;
  margin-bottom: 3rem;  
}

.archive-item {
  margin-bottom: 1rem;
  font-size:18px;
  overflow: hidden; /* clearfix */
}

.archive-item + .archive-year {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  color: #303030;
}

.archive-date {
  margin-top: 0;
  margin-bottom: 0;
}

.archive {
  a {
    text-decoration: none;
    border-bottom: none;    
  }
}

.archive-item a {
  color: #616161;
}

.project-img {
    border: 1px solid rgba(0,0,0,.1);
    border-radius: .25rem;
    cursor:pointer;
}

@media (min-width: 34rem) {
  .archive-item {
    margin-bottom: .5rem;
  }
  .archive-date {
    float: right;
    margin-left: 1rem;
  }
}

@media (max-width: 770px) {  
  .archive > .archive-item {
  background:#fff;
  border-right-color:#fff;
    text-decoration: none;
    border:none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
    transition: none;
  }
   .archive-date {
   border-right-color:#fff;
        text-decoration: none;
    border:none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
    transition: none;
   }

  .archive-item {
  border-right-color:#fff;
    line-height:1.3em;
    text-decoration: none;
    border:none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
    transition: none;
  }
}

