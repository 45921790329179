$gray-1: #f9f9f9;
$gray-2: #ccc;
$gray-3: #767676;
$gray-4: #515151;
$gray-5: #313131;

$red: #ac4142;
$orange: #d28445;
$yellow: #f4bf75;
$green: #90a959;
$cyan: #75b5aa;
$blue: #268bd2;
// $blue: #6a9fb5;
$brown: #8f5536;

$root-font-family: 'Nunito Sans', sans-serif;

$root-font-size: 18px;
$root-line-height: 1.75;

$body-color: #616161;
$body-bg: #fff;
$link-color: #069;

$border-color: #e5e5e5;

$large-breakpoint: 38em;
$large-font-size: 18px;

$code-font-family: Menlo, Monaco, "Courier New", monospace;
$code-color: #bf616a;
