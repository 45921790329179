// Typography
//
// Headings, body text, lists, and other misc typographic elements.


h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  line-height: 1.25;
  color: #616161;
  text-rendering: optimizeLegibility;
  font-weight:400;
}

h1 {
  font-size: 1.6rem;
  margin-bottom:1rem;
}

h2 {
  margin-top: 1.2rem;
  margin-bottom: 1.5rem;
  font-size: 1.4rem; 
  font-weight:normal; 
}

h3 {
  margin-top: 1.2rem;
  margin-bottom: 0.7rem;
  font-size: 1.2rem;
  color:#2d2d2d;
}

h4, h5, h6 {
  margin-top: 1rem;
  font-size: 1rem;
}

p {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

strong {
  color: #303030;
  font-weight: 500;
}

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5rem;
}

hr {
  position: relative;
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff;
}

abbr {
  font-size: 85%;
  font-weight: 500;
  color: #555;
  text-transform: uppercase;

  &[title] {
    cursor: help;
    border-bottom: 1px dotted #e5e5e5;
  }
}

blockquote {
  padding: .5rem 1rem;
  margin: .8rem 0;
  color: #7a7a7a;
  border-left: .25rem solid #e5e5e5;

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 30em) {
    padding-right: 5rem;
    padding-left: 1.25rem;
  }
}


// Markdown footnotes
//
// See the example content post for an example.

// Footnote number within body text
a[href^="#fn:"],
// Back to footnote link
a[href^="#fnref:"] {
  display: inline-block;
  margin-left: .1rem;
  font-weight: 500;
}

// List of footnotes
.footnotes {
  margin-top: 2rem;
  font-size: 85%;
}

// Custom type
//
// Extend paragraphs with `.lead` for larger introductory text.

.lead {
  font-size: 1.25rem;
  font-weight: 500;
}

@media (max-width: 34em) {
  h1 {
    font-size: 1.5rem;
  }
  p {
    font-size:18px;
  }
  
}


