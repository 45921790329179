// Layout
//
// Styles for managing the structural hierarchy of the site.

.container {
  
  padding-left:  1.5rem;
  padding-right: 1.5rem;
  margin-left:  auto;
  margin-right: auto;
}

.body {
  max-width: 36rem;
  margin:0px auto;
  margin-top:2rem;
}

footer {
  margin: 0px auto;
  margin-top: 4rem;
  margin-bottom: 1rem;
  text-align:center;
}

/* #Thank you
================================================== */

table.thank-you-items {
    margin-top: 40px;
    background:#fff;
    border:none;
    vertical-align: middle
}

table.thank-you-items td {
    height: 130px;
    background:#fff;
    border:none;
    border-bottom:1px solid #e5e5e5;
}

table.thank-you-items td form{
    height: 52px;
}

table.thank-you-items td p {
    margin: 0;
    padding: 0;
}

.thank-you-items tbody tr:nth-child(odd) td, tbody tr:nth-child(odd) th {
  background:#fff;
}



table.thank-you-items td span {
    font-size: 16px;
    color:#596270;
}

.paypal-disclaimer {
    text-align:center;
    color:#8E96A1;
}

// Resume

.resume {
  max-width: 36rem;

  margin-left:  auto;
  margin-right: auto;
  padding-bottom: 1.5rem;
}

.page .btn,.resume .btn {
	font-family: $root-font-family;
	
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  background-color: #fff;
  border-color: #ddd;
  height: 40px;
  margin-top: 15px;   
}
.page .btn:hover {
	background-color:#ddd;
}
.resume .btn {
  margin-top:0;
  float:right;
}
.resume p {

  font-size:16px;
}
.resume h1 {
	font-size: 1.2em;
	font-weight:normal;
  margin-top:0;
	color:#616161;
}
.resume h2 {
	font-weight:normal;
	color: #616161;
	font-size:1.4em;
	margin-top: 1.5rem;
  margin-bottom: 0.7rem;
}
.resume h3 {
	margin-bottom:0;
  margin-top: 1.5em;
  font-size:1em;
}
.resume .contact-details {
	font-size: 16px;
}
.resume p.subhead {
  margin-top:0;
	margin-bottom: 0;
}
.resume p {
	margin-bottom: 0.5rem;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}


/* #Badges
================================================== */

.badge-dead {
    background-color: #fff;
}
.badge-fun {
    background-color: #fff;
}
.badge-app {
    background-color: #fff;
}

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 65%;
    font-weight: 500;
    line-height: 1;
    color: #9c9c9c;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem;
    vertical-align:middle;
    margin-top: -0.2em;
    margin-left:5px;
    border: 1px solid #9c9c9c;
}






