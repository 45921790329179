// Masthead
//
// Super small header above the content for site name and short description.

.masthead {
  margin: 0px auto;
  font-size: 1rem;
  color: #777;
  padding: 2rem 0rem;
  max-width: 36rem;
}

.masthead-title {
  margin-top: 0;
  margin-bottom: 0;
  color: $gray-4;
  font-weight: 400;

  a {
    color: inherit;
  }

  small {
    font-size: 75%;    
    opacity: .5;
  }
}

.nav {
  padding-left: 0;
  font-size:18px;
  font-weight: 400;
  margin-bottom: 0;
  list-style: none;
  margin-top:12px;

  li {
    display: inline-block;
    a {
      color: #616161;
    }
  }
  
  li + li {
    margin-left: .5rem;
  }
}

 

@media (min-width: 36em) {
  .masthead {    
    text-align: right;
  }
  .masthead-title {
    float: left;    
  }
  ul.nav {
    margin-top:0;
  }
}

@media (max-width: 770px) {  
  .masthead {
    padding:0;
  }
  body {
    padding-top:1rem;
  }
  .archive .archive-item .project-button {
    display: none;
  }

}
